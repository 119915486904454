import { graphql } from "gatsby"

// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

// import { RelatedSectionProps, RelatedSection } from "../components/Content/RelatedSection"

import { HeroRight } from "../components/Hero/HeroImageHandlers"
import getBannerProps from "../components/Hero/getHeroBannerProps"

/**
 * @description Bygges opp likt som de andre sidene, oversikt, ingress, visning av kort
 * kort sorteres etter nyeste først (created date)
 * Her viser vi da to rader med kort
 * 1. Feature artikler, sortert på nyeste først
 * 2. Så vanlige artikler, nyeste først.
 * @param param0
 */
const CategoryTemplate = ({ location, data }) => {
  const { contentfulCategories: content } = data

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  // TODO: Legg opp relaterte artikler seksjon
  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-base max-w-prose lg:max-w-none">
                <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">{content.category}</h2>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"></h3>
              </div>
              <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="relative lg:row-start-1 lg:col-start-2">
                  <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                    <header>
                      <HeroRight {...heroBannerProps} />
                    </header>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0">
                  <div className="text-base max-w-prose mx-auto lg:max-w-none">
                    <p className="text-lg text-gray-500"></p>
                  </div>

                  <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </main>
    </>
  )
}

export default CategoryTemplate

export const categoryQuery = graphql`
  query categoriesQuery($slug: String!) {
    contentfulCategories(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      category
      contentful_id
      id
      videoUrl
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      slug
      node_locale
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
          }
        }
      }
      author {
        firstName
        lastName
        name
      }
      generic {
        metadataDescription
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
      article {
        id
        ingress {
          childMdx {
            timeToRead
          }
          ingress
        }
        author {
          firstName
          lastName
          name
        }
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
        }
      }
    }
  }
`
